import React, {Component} from "react";
import Typography from "@mui/material/Typography";
import {Button, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import {content} from "../../globals/content";

export default class AccountPanel extends Component {
    render() {
        return (
            <div style={{padding: "10px"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div><Typography variant="h5">{content.accountPanelContent[localStorage.getItem("siteLanguage") || "en-US"].title}</Typography></div><div>

                    <IconButton variant="contained" onClick={() => {
                        this.props.toggleAccountDrawer("right", false)
                    }
                    }>
                        <CloseIcon />
                    </IconButton>
                </div>
                </div>

                <div style={{display: "flex", justifyContent: "space-between", marginTop: 10, borderBottom: "1px solid #eee", paddingBottom: "20px"}}>
                    <div style={{width: "48%"}}><Avatar
                        key={"user_avatar"}
                        sx={{
                            bgcolor: "#eee",
                            width: 100,
                            height: 100,
                            fontSize: ".8rem",
                            color: "#333"
                        }}
                    >X</Avatar></div>
                    <div style={{width: "48%"}}>
                        <Typography variant="h6" style={{textAlign: "left", alignSelf: "center"}} display="inline">{localStorage.getItem("displayName")}</Typography>
                    </div>
                </div>
                <div style={{marginTop: 10}}><Typography variant="h6" display="inline">Email:</Typography> {localStorage.getItem("ownerEmail")}</div>
                <div style={{marginTop: 10, display: "none"}} ><Typography variant="h6" display="inline">Created:</Typography> {localStorage.getItem("displayName")}</div>



                <div style={{marginTop: 20, borderTop: "1px solid #eee", paddingTop: "10px", textAlign: "center"}}>
                    <Button>Change Password</Button>
                </div>
            </div>
        )
    }
}
