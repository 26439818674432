import React, {Component} from "react";
import Typography from "@mui/material/Typography";
import {Button, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import LanguageSelector from "../embeddedSelectors/languageSelector";
import {content} from "../../globals/content";

export default class SettingsPanel extends Component {

    state = {
        language: ""
    }

    setLanguage = (language) => {

    }

    render() {
        return (
            <div style={{padding: "10px"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div><Typography variant="h5">{content.settingsPanelContent[localStorage.getItem("siteLanguage") || "en-US"].title}</Typography></div><div>

                    <IconButton variant="contained" onClick={() => {
                        this.props.toggleSettingsDrawer("right", false)
                    }
                    }>
                        <CloseIcon />
                    </IconButton>
                </div>
                </div>

                Default Language<br />
                Autoplay audio responses<br />
                My Voice Gender<br />
                <br />


                <div style={{margin: "0 auto"}}>
                <LanguageSelector language={this.state.language} setLanguage={this.setLanguage}
                                  label={"My Default Language"} mutliSelect={false}/>
                <FormControl sx={{ m: 0, width: 300 }}>
                    <InputLabel htmlFor="demo-multiple-name-label">xx</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={""}
                        input={<OutlinedInput label="Language" />}
                        onChange={(e) => {

                        }}
                        style={{textAlign: "left"}}

                    >
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 0, width: 300 }}>
                    <InputLabel htmlFor="demo-multiple-name-label">My Voice Gender</InputLabel>
                    <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        value={""}
                        input={<OutlinedInput label="Language" />}
                        onChange={(e) => {

                        }}
                        style={{textAlign: "left"}}

                    >
                        <MenuItem
                            key={"male"}
                            value={"male"}
                        >
                            <div style={{display: "flex", flexDirection: "row"}}>Male</div>
                        </MenuItem>
                        <MenuItem
                            key={"female"}
                            value={"female"}
                        >
                            <div style={{display: "flex", flexDirection: "row"}}>Female</div>
                        </MenuItem>
                    </Select>
                </FormControl>
                </div>



            </div>
        )
    }
}
