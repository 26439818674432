import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import "firebase/compat/database";
import {} from "firebase/compat/storage";

const config = {
    apiKey: process.env.REACT_APP_STR_FIRE_API_KEY,
    authDomain: process.env.REACT_APP_STR_FIRE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_STR_FIRE_DATABASE_URL,
    appId: process.env.REACT_APP_STR_FIRE_APP_ID,
    messagingSenderId: process.env.REACT_APP_STR_FIRE_MESSAGING_SENDER_ID,
    projectId: process.env.REACT_APP_STR_FIRE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STR_FIRE_STORAGE_BUCKET,

};

const firebaseApp = firebase.initializeApp(config);

export const auth = firebase.auth();
export const db = firebase.database();
export const storage = firebase.storage();
export const storageRef = firebase.storage().ref();
