
export function setLocalStorageVals(userObj) {}

export function createChatSession(sessionId) {
    localStorage.setItem("cSessionId", sessionId);
}

export function createChatSessionUser(userId) {
    localStorage.setItem("cSessionUserId", userId);
}

export function createSelectedAccount(selectedAccountId) {
    //console.log("createSelectedAccount", selectedAccountId);
    localStorage.setItem("selectedAccount", selectedAccountId);
}

export function clearChatSession() {
    localStorage.setItem("cSessionId", "");
}

export function clearChatSessionUser() {
    localStorage.setItem("cSessionUserId", "");
}

export function clearSelectedAccount() {
    localStorage.setItem("selectedAccount", "");
}

export function languageEval(lang) {
    if (lang === "en") {
        return "en-US"
    } else if (lang === "es") {
        return "es-ES"
    }  else if (lang === "fr") {
        return "fr-FR"
    }
}

