import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {req_reset_password} from "../../helpers/requests";
import {content} from "../../globals/content";


const theme = createTheme();

export default function Resetpass() {

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        req_reset_password(data.get('email'))
            .then(function(result) {
                if (result.status === 200){
                    window.location = '/home';
                } else {
                    console.log("there was an error attempting to reset the password.")
                }
            })
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {content.resetPasswordScreenContent[localStorage.getItem("siteLanguage") || "en-US"].title}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={content.resetPasswordScreenContent[localStorage.getItem("siteLanguage") || "en-US"].email}
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1, mb: 2, backgroundColor: "#424242", textTransform: 'none' }}
                        >
                            {content.resetPasswordScreenContent[localStorage.getItem("siteLanguage") || "en-US"].resetButton}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/" variant="body2">
                                    {content.resetPasswordScreenContent[localStorage.getItem("siteLanguage") || "en-US"].loginLinkText}
                                </Link>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>

            </Container>
        </ThemeProvider>
    );
}
