import React, {Component, useEffect} from "react";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import TranslateIcon from '@mui/icons-material/Translate';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import Avatar from "@mui/material/Avatar";
import CloseIcon from '@mui/icons-material/Close';
import {Button, IconButton} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import EditIcon from '@mui/icons-material/Edit';

export default class ChatHeader extends Component {
    state = {
        rows: [],
        sessionObject: {},
        currentSessionId: ""
    }


    componentDidMount()
    {
        console.log("header mounted")
        this.setState({remount: true})
    }


    shouldComponentUpdate(nextProps, nextState) {
        console.log("ChatHeader mounted")
        if (!this.state.currentSessionId) {
            this.setState({currentSessionId: localStorage.getItem("cSessionId")})
        }

        if (this.state.currentSessionId !== nextProps.chatId) {
            let self = this;
            let sessions = JSON.parse(localStorage.getItem("chatSessions"));
            sessions.forEach((session) => {
                if (session.id === localStorage.getItem("cSessionId")) {
                    self.setState({sessionObject: session, currentSessionId: localStorage.getItem("cSessionId")})
                }
            })
            return true;
        }
        return false;
    }


    getSessionTypeImage = (sessionType) => {
        if (this.state.sessionObject) {
            if (this.state.sessionObject.sessionType === "conversation") {
                return (<QuestionAnswerIcon />)
            } else if (this.state.sessionObject.sessionType === "translate") {
                return (<TranslateIcon />)
            } else if (this.state.sessionObject.sessionType === "shared") {
                return (<InterpreterModeIcon />)
            }
        }
    }

    render() {
        return (
            <div style={{display: "flex", justifyContent: "space-between"}}>
                { this.state.sessionObject.id ?
                    <div style={{display: "flex", justifyContent: "left"}}>
                        <div style={{paddingRight: "10px", paddingTop: "10px"}}><this.getSessionTypeImage /></div>
                        <div style={{fontSize: ".9rem"}}>Chat1 <EditIcon style={{fontSize: "16px"}} />
                            <br />
                            <div style={{fontSize: ".7rem"}}>
                                [{ this.state.sessionObject && this.state.sessionObject.hasOwnProperty("languages") ? this.state.sessionObject.languages.join(', ') : "" }]
                             <span style={{display: "none"}}>Created: {this.state.sessionObject ? new Date(this.state.sessionObject.startTime).toLocaleString('en-US') : ""}</span>
                            </div>
                        </div>
                        {(this.state.sessionObject && this.state.sessionObject.hasOwnProperty("sessionType") && this.state.sessionObject.sessionType === "conversation") ?
                            <div style={{
                                marginLeft: "10px",
                                paddingLeft: "10px",
                                paddingTop: "7px",
                                borderLeft: "1px solid #ddd"
                            }}>{this.state.sessionObject.sessionMembers.map((member) => {
                                return (<Avatar
                                    key={member.userId}
                                    sx={{
                                        bgcolor: "#ddd",
                                        width: 24,
                                        height: 24,
                                        fontSize: ".7rem",
                                        color: "#333",
                                        marginRight: "5px",
                                        textAlign: "center",
                                        display: "inline-block",
                                        paddingTop: "5px"
                                    }}
                                >{member.displayName[0]}</Avatar>)
                            })
                            }</div> : null
                        }

                        {(this.state.sessionObject && this.state.sessionObject.sessionType === "conversation") ?
                        <div style={{marginLeft: "5px"}}>
                            <IconButton
                                    onClick={() => {this.props.openChatInvite(this.state.sessionObject)}}
                                    ><GroupAddIcon /></IconButton>
                        </div> : null
                        }

                            <div style={{marginLeft: "5px", borderLeft: "1px solid #ddd"}}>
                                <IconButton
                                    onClick={() => {
                                        this.props.openChatDelete(this.state.sessionObject)
                                    }}
                                ><DeleteForeverIcon/></IconButton>
                            </div>
                    </div>
                    : null
                }
                    <div><IconButton onClick={this.props.clearSession}><CloseIcon /></IconButton></div>
            </div>
        )
    }
}
