import React, {Component} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Drawer from '@mui/material/Drawer';
import Avatar from '@mui/material/Avatar';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {content} from "../../globals/content";

export default class SessionSelector extends Component {
    state = {
        rows: [],
    }


    componentDidMount()
    {
        console.log("SessionSelector mounted")
        this.loadSessions()
    }

    loadSessions = () => {
        let self = this;

        let result = JSON.parse(localStorage.getItem("chatSessions"));

        console.log(result, self.props.sessionType)
        let sessions = []
        if (self.props.sessionType) {
            result.forEach((session) => {
                if (session.sessionType === self.props.sessionType) {
                    sessions.push(session)
                }
            })
        } else {
            sessions = result;
        }

        self.setState({rows: sessions})


    }


    formatMembers = (members) => {
        let membersString = ""
        members.forEach((member) => {
            membersString += member + ", "
        })
        return membersString
    }

    joinSession = (row) => {
        let lang = "";
        row.sessionMembers.forEach((member) => {
            if (member.userId === localStorage.getItem("uid")){
                console.log("found")
                lang = member.language;
            }
        })

        this.props.joinSession(row.id, lang, row)
    }


    render() {
        return (
            <div style={{ padding: "1px"
            }}>
                {this.state.rows.length > 0 ?
                <TableContainer component={Paper}>
                    <Table sx={{ }} >
                        <TableHead>
                            <TableRow key={"tablehead"}>

                                <TableCell component="th" align="left">{content.sessionsTableContent[localStorage.getItem("siteLanguage") || "en-US"].memberHeader}</TableCell>
                                <TableCell component="th" align="left">{content.sessionsTableContent[localStorage.getItem("siteLanguage") || "en-US"].languageHeader}</TableCell>
                                <TableCell component="th"></TableCell>
                                <TableCell component="th" align="left" style={{display: "none"}}>Created</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.rows.map((row) => (
                                <TableRow key={row.id}>

                                    <TableCell align="left">
                                        {row.sessionMembers.map((member) => {
                                            return (<Avatar
                                                key={member.userId}
                                                sx={{
                                                    bgcolor: "#eee",
                                                    width: 24,
                                                    height: 24,
                                                    fontSize: ".8rem",
                                                    color: "#333",
                                                    textAlign: "center",
                                                    paddingTop: "5px",
                                                    display: "inline-block"
                                                }}
                                            >{member.displayName[0]}</Avatar>)
                                        })
                                        }
                                    </TableCell>
                                    <TableCell align="left">{row.languages.join(", ")}</TableCell>
                                    <TableCell align="left"><Link onClick={() => {this.joinSession(row)}}>
                                        <OpenInNewIcon style={{fontSize: "1.1rem"}} />
                                    </Link></TableCell>
                                    <TableCell align="left" style={{display: "none"}}>{new Date(row.startTime).toLocaleString('en-US')}</TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>

                    </Table>
                </TableContainer>
                : <div>No sessions available</div>}


            </div>
        )
    }
}
