import {Button, IconButton, TextField} from "@mui/material";
import ScrollableFeed from "react-scrollable-feed";
import MessageHistory from "../messageHistory";
import React, {useEffect, useState} from "react";
import SendIcon from '@mui/icons-material/Send';
import MicIcon from '@mui/icons-material/Mic';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VideocamIcon from '@mui/icons-material/Videocam';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import AssistantIcon from '@mui/icons-material/Assistant';
import SettingsIcon from '@mui/icons-material/Settings';
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import HearingIcon from '@mui/icons-material/Hearing';
import CloseIcon from '@mui/icons-material/Close';
import Groups2Icon from '@mui/icons-material/Groups2';
import TranslateIcon from '@mui/icons-material/Translate';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {db} from "../../helpers/firebase";
import { v4 as uuidv4 } from 'uuid';
import {req_get_history} from "../../helpers/requests";
import useSpeechToText, { ResultType } from "../Hooks";
import Popover from '@mui/material/Popover';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import "./chat.css"
import Typography from "@mui/material/Typography";
import CreateChat from "../dialogs/createChat";
import {clearChatSession, clearChatSessionUser, createChatSession, createChatSessionUser} from "../../helpers/session";
import Drawer from "@mui/material/Drawer";
import SessionSelector from "../embeddedSelectors/sessionSelector";
import BottomNavigation from '@mui/material/BottomNavigation';
import ChatHeader from "../headers/chatHeader";
import AccountPanel from "../dialogs/accountPanel";
import SettingsPanel from "../dialogs/settingsPanel";
import InviteMember from "../dialogs/inviteMember";
import ChatDelete from "../dialogs/chatDelete";
import {content} from "../../globals/content";

export default function Chat(props) {

    const [language, setLanguage] = useState(props.selLang);
    const [otherLanguage, setOtherLanguage] = useState("en-US");
    const [messages, setMessages] = useState([]);
    const [chatSessionId, setChatSessionId] = useState(props.chatSessionId);
    const [interfacex, setInterface] = useState({ });
    const [waiting, setWaiting] = useState(false);
    const [projectId, setProjectId] = React.useState(window.location.pathname.replace("/", "").split("_")[0]);
    const [workflowStageId, setWorkflowStageId] = React.useState("");
    const [isVideoActive, setIsVideoActive] = React.useState(false);
    const [isAudioActive, setIsAudioActive] = React.useState(true);
    const [entryText, setEntryText] = useState("");
    const [location, setLocation] = React.useState({latitude: "", longitude: ""});
    const [accountId, setAccountId] = useState(localStorage.getItem("selectedAccount"));
    const [messagesLoaded, setMessagesLoaded] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const [loaded, setLoaded] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [createChatOpen, setCreateChatOpen] = React.useState(false);
    const [chatInviteOpen, setChatInviteOpen] = React.useState(false);
    const [chatDeleteOpen, setChatDeleteOpen] = React.useState(false);
    const [conversationType, setConversationType] = React.useState("");
    const [sessionCode, setSessionCode] = React.useState("");
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [accountDrawerOpen, setAccountDrawerOpen] = React.useState(false);
    const [settingsDrawerOpen, setSettingsDrawerOpen] = React.useState(false);
    const [drawerSessionType, setDrawerSessionType] = React.useState(true);
    const [drawerSessionDisplay, setDrawerSessionDisplay] = React.useState("");
    const [chatDetailObj, setChatDetailObj] = React.useState(null);
    const [otherInvoker, setOtherInvoker] = React.useState(false);


    const {
        error,
        interimResult,
        isRecording,
        results,
        isSpeaking,
        startSpeechToText,
        stopSpeechToText
    } = useSpeechToText({
        continuous: false,
        crossBrowser: true,
        googleApiKey: process.env.REACT_APP_API_KEY,
        speechRecognitionProperties: { interimResults: true, lang:language },
        useLegacyResults: false,
        enableAutomaticPunctuation: true,
        diarizationConfig: {
            enableSpeakerDiarization: false,
            minSpeakerCount: 2,
            maxSpeakerCount: 6
        },
        googleCloudRecognitionConfig: {
            encoding: 'LINEAR16',
            languageCode: language,
            enableAutomaticPunctuation: true,
            diarizationConfig: {
                enableSpeakerDiarization: false,
                minSpeakerCount: 2,
                maxSpeakerCount: 6
            },
        },
    });

    useEffect(() => {
        console.log("chat load", chatSessionId);

        let sessions = JSON.parse(localStorage.getItem("chatSessions"));
        let tempLang = "";
        sessions.forEach((session) => {
            if (session.id === localStorage.getItem("cSessionId")) {
                session.sessionMembers.forEach((member) => {
                    if (member.userId === localStorage.getItem("uid")){
                        setLanguage(member.language);
                        tempLang = member.language;
                        if (session.sessionType === "shared")
                        {
                            session.languages.forEach((lang) => {
                            if (lang !== member.language) {
                                    setOtherLanguage(lang);
                                }
                            })
                        }
                    }

                })
            }
        })
        //
    }, [chatSessionId]);

    useEffect(() => {
        let last = {};
        console.log("results", results)
        if (results.length > 0) {
            last = results.pop();
            console.log("last", last)
            if (last.hasOwnProperty("transcript") && last.transcript !== "") {
                setEntryText(last.transcript)
                setRef(true, last.transcript, null, "user", "")
            }
        }
    }, [results])

    const toggleLeftDrawer = (anchor, open, sessionType) => {
        console.log("toggleDrawer2", anchor, open);
        setDrawerSessionType(sessionType);
        if (sessionType === "translate") {
            setDrawerSessionDisplay("Personal Translate Sessions");
        } else if (sessionType === "conversation") {
            setDrawerSessionDisplay("Group Conversations");
        } else if (sessionType === "shared") {
            setDrawerSessionDisplay("Shared Display Conversations");
        }
        setDrawerOpen(open);
    }

    const toggleAccountDrawer = (anchor, open) => {
        setAccountDrawerOpen(open);
    }

    const toggleSettingsDrawer = (anchor, open) => {
        setSettingsDrawerOpen(open);
    }

    const setQuery = (e) => { setEntryText(e.target.value) }

    const onEnterPress = (e) => {
        if(e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            sendMessage(e)
        }
    }

    const resetSession = () => {
        const items = { ...localStorage };
        console.log("allitems", items)
        localStorage.clear();
        sessionStorage.clear();
        setMessages([]);
        window.location.reload();
    }

    const updateSession = (sessionId, language, row = null) => {
        console.log("updateSession", sessionId, language, row)
        if (row) {
            setChatDetailObj(row);
        }
        clearSession();
        createChatSession(sessionId);
        createChatSessionUser(localStorage.getItem("uid"));
        setChatSessionId(sessionId);
        setCreateChatOpen(false);
        setLanguage(language);
        setDrawerOpen(false);
        loadData(sessionId);
    }

    const clearSession = () => {
        console.log("clearSession")
        setFirstLoad(true);
        setSessionCode("");
        setConversationType("");
        setChatSessionId("");
        clearChatSession();
        clearChatSessionUser();
    }

    useEffect(() => {
        setChatSessionId(props.chatSessionId);
        loadData(props.chatSessionId);
    }, [])

    //useEffect(() => {
    //    console.log("a speaking", isSpeaking)
    //}, [isSpeaking])

    const toggleAudio = () => { setIsAudioActive(!isAudioActive); }

    const loadData = (sessionId) => {
        const params = new URLSearchParams(window.location.search);
        let newMessages = [];

        req_get_history(sessionId)
            .then(function (result) {
                if (result.status === 200) {

                    let historyData = result.data.data;
                    let chatHistory = historyData["history"]["exchangeLog"] || [];
                    setMessagesLoaded(false)
                    chatHistory.forEach((chat) => {

                        if (chat["conveyor"] === "user") {
                            newMessages.push({sender: "user", ...chat});
                        }
                        if (chat["conveyor"] === "assistant") {
                            newMessages.push({sender: "assistant", ...chat});
                        }
                    });

                    let tempFirstLoad = firstLoad;
                    if (newMessages.length === 0) {
                        tempFirstLoad = false;
                    }
                    setFirstLoad(false);

                    console.log("tempFirstLoad", tempFirstLoad)

                    try {

                        let messageId = uuidv4().toString()
                        db.ref("/translation/" + accountId + "/" + sessionId + "/c").on("value", snapshot => {
                            //console.log("receiving", snapshot.val())

                            if(snapshot.val() !== null) {
                                if (snapshot.val().hasOwnProperty("bypass") && snapshot.val().bypass === true) {
                                    setWaiting(false);
                                } else {

                                    if (snapshot.val() && tempFirstLoad) {
                                        if (newMessages.length === 0) {
                                            newMessages.push(snapshot.val());
                                        }
                                        if (snapshot.val().sender !== "user") {
                                            console.log("e1")
                                            setFirstLoad(false);
                                            setWaiting(false);
                                        }
                                    } else {
                                        console.log("e2", tempFirstLoad)
                                        if (!tempFirstLoad) {
                                            newMessages.push(snapshot.val());
                                        }
                                        setFirstLoad(false);
                                        setWaiting(false);
                                    }
                                }
                            }

                            setFirstLoad(false);
                            setMessages([...newMessages]);
                            tempFirstLoad = false;
                        });

                        setLoaded(true);
                        setFirstLoad(false);

                    } catch (error) {
                        console.log("fail", error)
                    }

                }

                setMessagesLoaded(true)
                setLoaded(true);
            })
    }


    const Waiter = () => { return (<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>) }

    const sendMessage = (e) => {
        e.preventDefault();
        setRef(true, entryText, null, "user", "");
    }

    const sendFuncMessage = (message, messageType, messageObject) => {
        setRef(true, message, null, "userFunc", messageType, messageObject);
    }

    const setRef = (createId, message, messageId, sender, messageType, messageObject={}) => {

        if (createId) { messageId = uuidv4().toString() }
        console.log("aa", chatSessionId, language)
        console.log("cSessionUserId", localStorage.getItem("cSessionUserId"))
        console.log("message", message)

        if (message !== undefined) {
            db.ref(`translation/${accountId}/${chatSessionId}/c`).set({
                message: message,
                messageId: messageId,
                messageType: messageType,
                workflowStageId: ["user", "userFunc"].includes(sender) ? workflowStageId : "",
                sender: sender,
                initialId: "",
                senderId: localStorage.getItem("cSessionUserId"),
                senderDisplayName: localStorage.getItem("displayName"),
                projectId: projectId,
                payload: {audio: isAudioActive, location: location},
                language: otherInvoker ? otherLanguage : language,
                sent: new Date().toISOString()
            });

            setEntryText("");
            setWaiting(true);
        }
        // set timer
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenCreateChat = () => {
        setCreateChatOpen(true);
    }

    const openChatInvite = (chatObj) => {
        console.log("chatObj", chatObj)
        setChatInviteOpen(true);
    }

    const openChatDelete = (chatObj) => {
        console.log("chatObj", chatObj)
        setChatDeleteOpen(true);
    }


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div className="homeContentContainer">
            <div style={{display: "flex", flexDirection: "row", gap: 10, marginLeft: "5px", marginRight: "5px"}}>
                <div style={{
                    minWidth: 32,
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "calc(100vh - 160px)",
                    maxHeight: "calc(100vh - 160px)",
                    justifyContent: "space-between",
                }}>

                    <div style={{paddingTop: "10px"}}>

                        <IconButton aria-describedby={id} variant="contained" onClick={() => {}
                        }>
                            <NotificationsIcon />
                        </IconButton>
                        <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].notificationsSubtext}</div>

                        <IconButton aria-describedby={id} variant="contained" onClick={() => {
                            toggleLeftDrawer("left", true, "conversation")}
                        }>
                            <QuestionAnswerIcon />
                        </IconButton>
                        <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].groupSubtext}</div>

                        <IconButton aria-describedby={id} variant="contained" onClick={() => {
                            console.log("toggleDrawer2");
                            toggleLeftDrawer("left", true, "translate")
                        }}>
                            <TranslateIcon />
                        </IconButton>
                        <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].translateSubtext}</div>

                        <IconButton aria-describedby={id} variant="contained" onClick={() => {
                            toggleLeftDrawer("left", true, "shared")
                        }}>
                            <InterpreterModeIcon />
                        </IconButton>
                        <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].sharedSubtext}</div>

                        <IconButton aria-describedby={id} variant="contained" onClick={() => {
                            setCreateChatOpen(true)
                        }}>
                            <AddCircleIcon />
                        </IconButton>
                        <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].addSubtext}</div>

                    </div>
                    <div>

                        <IconButton aria-describedby={id} variant="contained" onClick={() => {
                            toggleAccountDrawer("left", true)}
                        }>
                            <ManageAccountsIcon />
                        </IconButton>
                        <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].accountSubtext}</div>

                        <IconButton aria-describedby={id} variant="contained" onClick={() => {
                            toggleSettingsDrawer("right", true)}
                        }>
                            <SettingsIcon />
                        </IconButton>
                        <div style={{fontSize: ".6rem"}}>{content.leftSidebarContent[localStorage.getItem("siteLanguage") || "en-US"].settingsSubtext}</div>
                    </div>
                </div>



                <div style={{
                    border: "1px solid #e7e7e7",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    minWidth: 300,
                    width: "100%"
                }}>

                    {chatSessionId ?
                        <>

                            <div style={{
                                textAlign: "left",
                                backgroundColor: "#f7f7f7",
                                padding: "5px",
                                fontWeight: "bold"
                            }}>
                                <ChatHeader detailObj={chatDetailObj} chatId={chatSessionId} openChatInvite={openChatInvite} openChatDelete={openChatDelete} clearSession={clearSession} />
                            </div>
                            <div style={{minHeight: "calc(100vh - 160px)", maxHeight: "calc(100vh - 160px)"}}>

                                {messages.length === 0 ?
                                    <div style={{textAlign: "center", marginTop: "20px"}}>no messages yet</div>
                                    :
                                    <ScrollableFeed
                                        id="output"
                                        className={" chat_scroller "}
                                    >
                                        <div className={"inner-chat"} id={"inner-chat"} style={{
                                            width: "100%",
                                            paddingBottom: "0",
                                            paddingLeft: "10px",
                                            paddingRight: "10px",
                                            marginTop: "10px",

                                        }}>
                                            <MessageHistory key={"messageHistory"}
                                                            messages={messages}
                                                            interfaceObject={interfacex}
                                            />
                                            {waiting ?
                                                <div style={{
                                                    borderRadius: "10px",
                                                    marginBottom: "5px",
                                                    marginLeft: "100px",
                                                    marginRight: "100px",
                                                    textAlign: "center"
                                                }}>
                                                    <Waiter key={"waiterDisplay"}/>
                                                </div> : null
                                            }
                                            <div
                                                style={{clear: "both"}}
                                                id={"bottomRef"}/>
                                        </div>
                                    </ScrollableFeed>
                                }


                            </div>
                            <BottomNavigation>
                            <div style={{width: "100%", paddingLeft: 10, paddingRight: 10, marginRight: "100px", marginLeft: chatDetailObj && chatDetailObj.hasOwnProperty("sessionType") && chatDetailObj.sessionType === "shared" ? "60px" : 0}}>

                                <TextField value={entryText}
                                           disabled={isSpeaking}

                                           onChange={(e) => {
                                               setQuery(e)
                                           }}
                                           style={{width: "100%", borderColor: "#ddeeff", backgroundColor: isSpeaking ? "#f7f7f7" : "#fff"}}
                                           onKeyDown={(e) => {
                                               onEnterPress(e)
                                           }}>
                                </TextField>

                                {chatDetailObj && chatDetailObj.hasOwnProperty("sessionType") && chatDetailObj.sessionType === "shared" ?
                                    <Button style={{
                                        position: "absolute",
                                        float: "left",
                                        left: "80px",
                                        marginTop: "0px",
                                        padding: 0,
                                        color: "#083f45",
                                        background: isRecording && otherInvoker ? "#c5d5b0" : "#fff"
                                    }}
                                            onClick={isRecording ?
                                                stopSpeechToText : () => {
                                                setOtherInvoker(true);
                                                startSpeechToText(otherLanguage);
                                            }
                                            }>
                                        {isSpeaking && otherInvoker ? <div><HearingIcon style={{margin: 0, padding: 0}}/>
                                            <div style={{"fontSize": ".7rem"}}>{otherLanguage}</div>
                                        </div> : <div><MicIcon/>
                                            <div style={{"fontSize": ".7rem"}}>{otherLanguage}</div>
                                        </div>}
                                    </Button> : null
                                }

                                <Button style={{
                                    position: "absolute",
                                    float: "right",
                                    right: "45px",
                                    marginTop: "0px",
                                    padding: 0,
                                    color: "#083f45",
                                    background: isRecording && !otherInvoker ? "#c5d5b0"  : "#fff"
                                }}
                                            onClick={isRecording ? stopSpeechToText : () => {
                                                setOtherInvoker(false);
                                                startSpeechToText(language);
                                            }}>
                                    {isSpeaking && !otherInvoker ? <div><HearingIcon style={{margin: 0, padding: 0}} /><div style={{"fontSize": ".7rem"}}>{language}</div></div> : <div><MicIcon/><div style={{"fontSize": ".7rem"}}>{language}</div></div>}
                                </Button>
                                <IconButton disableRipple={true} disableFocusRipple={true} style={{
                                    position: "absolute",
                                    float: "right",
                                    right: "10px",
                                    marginTop: "2px",
                                    boxShadow: 'none',
                                    color: "#083f45"
                                }}
                                            onClick={(e) => {
                                                sendMessage(e)
                                            }}
                                ><SendIcon/></IconButton>
                            </div>
                            </BottomNavigation>
                        </> : <div style={{padding: 10, display: "flex", gap: 10, flexFlow: "wrap", justifyContent: "center"}}>

                            <Card sx={{ minWidth: 275, maxWidth: 275, backgroundColor: "#f5f5f5" }}>
                                <CardContent>

                                    <Typography variant="h6" component="div">
                                        Create a Chat!
                                    </Typography>

                                </CardContent>
                                <CardActions>
                                    <Button onClick={() => {setCreateChatOpen(true)}} size="small">Get Started</Button>
                                </CardActions>
                            </Card>
                            <Card sx={{ minWidth: 275, maxWidth: 275, backgroundColor: "#f5f5f5" }}>
                                <CardContent>
                                    <Typography variant="h6" component="div">
                                        Join a Chat!
                                    </Typography>

                                </CardContent>
                                <CardActions>
                                    <Button onClick={() => {setCreateChatOpen(true)}} size="small">Get Started</Button>
                                </CardActions>
                            </Card>
                            <Card sx={{ minWidth: 275, maxWidth: 275, backgroundColor: "#f5f5f5" }}>
                                <CardContent>

                                    <Typography variant="h6" component="div">
                                        Recent Group Chats
                                    </Typography>

                                    <Typography variant="body2">
                                        list past n records

                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={() => {toggleLeftDrawer("left", true, "conversation")}}
                                            size="small">Show All</Button>
                                </CardActions>
                            </Card>

                            <Card sx={{ minWidth: 275, maxWidth: 275, backgroundColor: "#f5f5f5" }}>
                                <CardContent>

                                    <Typography variant="h6" component="div">
                                        Recent Translations
                                    </Typography>

                                    <Typography variant="body2">
                                        list past n records

                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        onClick={() => {toggleLeftDrawer("left", true, "translate")}}
                                        size="small">Show All</Button>
                                </CardActions>
                            </Card>
                            <Card sx={{ minWidth: 275, maxWidth: 275, backgroundColor: "#f5f5f5" }}>
                                <CardContent>

                                    <Typography variant="h6" component="div">
                                        Recent Shared Screen Chats
                                    </Typography>

                                    <Typography variant="body2">
                                        list past n records

                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        onClick={() => {toggleLeftDrawer("left", true, "shared")}}
                                        size="small">Show All</Button>
                                </CardActions>
                            </Card>
                        </div>
                    }
                </div>
                <Drawer
                    PaperProps={{
                        sx: { maxWidth: "400px", width: "100%", maxHeight: "100%", marginTop: "46px", textAlign: "left"},
                    }}
                    anchor={"right"}
                    open={accountDrawerOpen}
                    onClose={() => {toggleAccountDrawer("right", false)}}
                >
                    <AccountPanel toggleAccountDrawer={toggleAccountDrawer} />
                </Drawer>

                <Drawer
                    PaperProps={{
                        sx: { maxWidth: "400px", width: "100%", maxHeight: "100%", marginTop: "46px", textAlign: "left"},
                    }}
                    anchor={"right"}
                    open={settingsDrawerOpen}
                    onClose={() => {toggleSettingsDrawer("right", false)}}
                >
                    <SettingsPanel toggleSettingsDrawer={toggleSettingsDrawer} />
                </Drawer>

            </div>

            <div
                style={{
                    border: "1px solid #e7e7e7",
                    width: "50%",
                    margin: '20px auto',
                    textAlign: 'center',
                    display: 'none'
                }}
            >
                <ul>
                    {results.map((result) => (
                        <li key={result.timestamp}>{result.transcript}</li>
                    ))}
                    {interimResult && <li>{interimResult}</li>}
                </ul>
            </div>

            {createChatOpen ? <CreateChat open={createChatOpen} updateSession={updateSession} close={() => {setCreateChatOpen(false)}} />
                : null
            }

            {chatInviteOpen ? <InviteMember open={chatInviteOpen} chatObj={chatDetailObj} updateSession={updateSession} close={() => {setChatInviteOpen(false)}} />
                : null
            }

            {chatDeleteOpen ? <ChatDelete open={chatDeleteOpen} chatObj={chatDetailObj} clearSession={clearSession} close={() => {setChatDeleteOpen(false)}} />
                : null
            }

            <Drawer

                PaperProps={{
                    sx: { maxWidth: "400px", width: "100%", height: "500px", maxHeight: "100%", marginTop: "45px", borderRadius: "3px"},
                }}
                anchor={"left"}
                open={drawerOpen}
                onClose={() => {toggleLeftDrawer("left", false, "")}}
            >
                <div style={{padding: "5px"}}>

                    {drawerSessionType === "conversation" ?
                        <Typography variant="h5">{content.chatScreenContent[localStorage.getItem("siteLanguage") || "en-US"].conversationModalHeader}</Typography> :
                        null
                    }
                    {drawerSessionType === "translate" ?
                        <Typography variant="h5">{content.chatScreenContent[localStorage.getItem("siteLanguage") || "en-US"].translateModalHeader}</Typography> :
                        null
                    }
                    {drawerSessionType === "shared" ?
                        <Typography variant="h5">{content.chatScreenContent[localStorage.getItem("siteLanguage") || "en-US"].sharedModalHeader}</Typography> :
                        null
                    }

                </div>
                <SessionSelector sessionType={drawerSessionType} joinSession={updateSession} />
            </Drawer>

        </div>
    )
}
