import * as React from 'react';
import Box from "@mui/material/Box";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Register from "./register";
import Login from "./login";
import {useEffect} from "react";
//import ReactGA from "react-ga4";
import {content} from "../../globals/content";

//ReactGA.initialize(process.env.REACT_APP_GA_ID1,[]);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const LoginConsole = (props) => {

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
        /*
        ReactGA.event({
            category: "login events",
            action: "toggle to " + newValue,
            label: "in app"
        });

        */
    };

    useEffect(() => {
        if (props.selectLogin){
            setValue(1);
        }

        /*
        ReactGA.send({ hitType: "pageview", page: "/home", title: "Login Panel" });
        ReactGA.event({
            category: "login events",
            action: "load",
            label: "load"
        });
        */

        }, [])

    const toggleRegister = () => {
        setValue(0);
    }



return (<div className="LoginConsole" style={{"border": "1px solid #eee", borderRadius: "3px", padding: "10px", maxWidth: "550px", margin: "10px auto"}}>
    <Box sx={{borderBottom: 1, borderColor: 'divider', width: '100%'}}>
        <Tabs value={value} onChange={handleChange} aria-label="register and login tabs">
            <Tab label={content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].title} {...a11yProps(0)} style={{textTransform: "none"}} />

            <Tab label={content.loginScreenContent[localStorage.getItem("siteLanguage") || "en-US"].title} {...a11yProps(1)} style={{textTransform: "none"}} />
        </Tabs>
    </Box>
    <TabPanel value={value} index={0}>
        <Register />
    </TabPanel>
    <TabPanel value={value} index={1}>
        <Login toggleRegister={toggleRegister} />
    </TabPanel>
</div>)
}

export default LoginConsole;
