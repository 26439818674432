export const content = {
    homeScreenContent: {
        "en-US": {
            title: "",
            subTitle: "The chat platform that breaks the language barrier."
        },
        "en-GB": {
            title: "",
            subTitle: "The chat platform that breaks the language barrier."
        },
        "es-ES": {
            title: "",
            subTitle: "La plataforma de chat que rompe la barrera del idioma."
        },
        "es-MX": {
            title: "",
            subTitle: "La plataforma de chat que rompe la barrera del idioma."
        },
        "fr-FR": {
            title: "",
            subTitle: "La plateforme de chat qui brise la barrière de la langue."
        },
        "de-DE": {
            title: "",
            subTitle: "Die Chat-Plattform, die die Sprachbarriere durchbricht."
        },
        "it-IT": {
            title: "",
            subTitle: "La piattaforma di chat che abbatte la barriera linguistica."
        },
        "hi-IN": {
            title: "",
            subTitle: "वह चैट प्लेटफ़ॉर्म जो भाषा की बाधा तोड़ता है"
        }
    },
    loginScreenContent: {
        "en-US": {
            title: "Log In",
            loginButton: "Log In",
            email: "Email Address",
            password: "Password",
            passwordResetLinkText: "Request Password Reset"
        },
        "en-GB": {
            title: "Log In",
            loginButton: "Log In",
            email: "Email Address",
            password: "Password",
            passwordResetLinkText: "Request Password Reset"
        },
        "es-ES": {
            title: "Inicie Sesión",
            loginButton: "Inicie Sesión",
            email: "Correo Electrónico",
            password: "Contraseña",
            passwordResetLinkText: "Restablecer contraseña"
        },
        "es-MX": {
            title: "Inicie Sesión",
            loginButton: "Inicie Sesión",
            email: "Correo Electrónico",
            password: "Contraseña",
            passwordResetLinkText: "Restablecer contraseña"
        },
        "fr-FR": {
            title: "Connectez-vous",
            loginButton: "Connectez-vous",
            email: "Adresse électronique",
            password: "Mot de Passe",
            passwordResetLinkText: "Demande de réinitialisation du mot de passe"
        },
        "de-DE": {
            title: "Anmelden",
            loginButton: "Anmelden",
            email: "E-Mail-Adresse",
            password: "Passwort",
            passwordResetLinkText: "Passwort zurücksetzen"
        },
        "it-IT": {
            title: "Accedi",
            loginButton: "Accedi",
            email: "Indirizzo Email",
            password: "Password",
            passwordResetLinkText: "Richiesta di reimpostazione della password"
        }
        ,
        "hi-IN": {
            title: "लॉग इन करें",
            loginButton: "लॉग इन करें",
            email: "ईमेल पता",
            password: "पासवर्ड",
            passwordResetLinkText: "पासवर्ड रीसेट का अनुरोध करें"
        }
    },
    registerScreenContent: {
        "en-US": {
            title: "Register",
            registerButton: "Register",
            email: "Email Address",
            password: "Password",
            displayName: "Display Name",
            privacyPolicyLinkText: "Privacy Policy",
            termsOfServiceLinkText: "Terms of Service",
            agreementStatement: "I have read and agree to the Privacy Policy and Terms of Service."
        },
        "en-GB": {
            title: "Register",
            registerButton: "Register",
            email: "Email Address",
            password: "Password",
            displayName: "Display Name",
            privacyPolicyLinkText: "Privacy Policy",
            termsOfServiceLinkText: "Terms of Service",
            agreementStatement: "I have read and agree to the Privacy Policy and Terms of Service."
        },
        "es-ES": {
            title: "Registrarse",
            registerButton: "Registrarse",
            email: "Correo Electrónico",
            password: "Contraseña",
            displayName: "Nombre para Mostrar",
            privacyPolicyLinkText: "Política de privacidad",
            termsOfServiceLinkText: "Términos de servicio",
            agreementStatement: "He leído y acepto la Política de privacidad y los Términos de servicio."
        },
        "es-MX": {
            title: "Registrarse",
            registerButton: "Registrarse",
            email: "Correo Electrónico",
            password: "Contraseña",
            displayName: "Nombre para Mostrar",
            privacyPolicyLinkText: "Política de privacidad",
            termsOfServiceLinkText: "Términos de servicio",
            agreementStatement: "He leído y acepto la Política de Privacidad y los Términos de Servicio."
        },
        "fr-FR": {
            title: "S'inscrire",
            registerButton: "S'inscrire",
            email: "Adresse électronique",
            password: "Mot de Passe",
            displayName: "Nom d'affichage",
            privacyPolicyLinkText: "Politique de confidentialité",
            termsOfServiceLinkText: "Conditions d'utilisation",
            agreementStatement: "J'ai lu et j'accepte la politique de confidentialité et les conditions d'utilisation."
        },
        "de-DE": {
            title: "Registrieren",
            registerButton: "Registrieren",
            email: "E-Mail-Adresse",
            password: "Passwort",
            displayName: "Anzeigename",
            privacyPolicyLinkText: "Datenschutzrichtlinie",
            termsOfServiceLinkText: "Nutzungsbedingungen",
            agreementStatement: "Ich habe die Datenschutzrichtlinien und Nutzungsbedingungen gelesen und stimme ihnen zu."
        },
        "it-IT": {
            title: "Registrarsi",
            registerButton: "Registrarsi",
            email: "Indirizzo Email",
            password: "Password",
            displayName: "Nome visualizzato",
            privacyPolicyLinkText: "Informativa sulla privacy",
            termsOfServiceLinkText: "Termini di servizio",
            agreementStatement: "Ho letto e accetto la politica sulla privacy e le condizioni di servizio."
        }
        ,
        "hi-IN": {
            title: "पंजीकरण करें",
            registerButton: "पंजीकरण करें",
            email: "ईमेल पता",
            password: "पासवर्ड",
            displayName: "प्रदर्शन नाम",
            privacyPolicyLinkText: "गोपनीयता नीति",
            termsOfServiceLinkText: "सेवा की शर्तें",
            agreementStatement: "मैंने गोपनीयता नीति और सेवा की शर्तें पढ़ ली हैं और मैं उनसे सहमत हूँ।"
        }
    },
    resetPasswordScreenContent: {
        "en-US": {
            title: "Password Reset",
            resetButton: "Request Password Reset",
            email: "Email Address",
            loginLinkText: "Return to login page"
        },
        "en-GB": {
            title: "Password Reset",
            resetButton: "Request Password Reset",
            email: "Email Address",
            loginLinkText: "Return to login page"
        },
        "es-ES": {
            title: "Restablecimiento de contraseña",
            resetButton: "Restablecer contraseña",
            email: "Correo Electrónico",
            loginLinkText: "Regresar a la página de inicio de sesión"
        },
        "es-MX": {
            title: "Restablecimiento de contraseña",
            resetButton: "Restablecer contraseña",
            email: "Correo Electrónico",
            loginLinkText: "Regresar a la página de inicio de sesión"
        },
        "fr-FR": {
            title: "Réinitialisation du mot de passe",
            resetButton: "Demande de réinitialisation du mot de passe",
            email: "Adresse électronique",
            loginLinkText: "Retourner à la page de connexion"
        },
        "de-DE": {
            title: "Passwort zurücksetzen",
            resetButton: "Passwort zurücksetzen",
            email: "E-Mail-Adresse",
            loginLinkText: "Zurück zur Anmeldeseite"
        },
        "it-IT": {
            title: "Reimposta password",
            resetButton: "Richiesta di reimpostazione della password",
            email: "Indirizzo Email",
            loginLinkText: "Torna alla pagina di accesso"
        }
        ,
        "hi-IN": {
            title: "पासवर्ड रीसेट",
            resetButton: "पासवर्ड रीसेट का अनुरोध करें",
            email: "ईमेल पता",
            loginLinkText: "लॉगिन पेज पर वापस जाएँ"
        }
    },
    leftSidebarContent: {
        "en-US": {
            notificationsSubtext: "Notifications",
            groupSubtext: "Group",
            translateSubtext: "Translate",
            sharedSubtext: "Shared Screen",
            addSubtext: "Add/Join",
            accountSubtext: "Account",
            settingsSubtext: "Settings"
        },
        "en-GB": {
            notificationsSubtext: "Notifications",
            groupSubtext: "Group",
            translateSubtext: "Translate",
            sharedSubtext: "Shared Screen",
            addSubtext: "Add/Join",
            accountSubtext: "Account",
            settingsSubtext: "Settings"
        },
        "es-ES": {
            notificationsSubtext: "Notificaciones",
            groupSubtext: "Grupo",
            translateSubtext: "Traducir",
            sharedSubtext: "Pantalla compartida",
            addSubtext: "Add/Join",
            accountSubtext: "Cuenta",
            settingsSubtext: "Configuración"
        },
        "es-MX": {
            notificationsSubtext: "Notificaciones",
            groupSubtext: "Grupo",
            translateSubtext: "Traducir",
            sharedSubtext: "Pantalla compartida",
            addSubtext: "Add/Join",
            accountSubtext: "Cuenta",
            settingsSubtext: "Configuración"
        },
        "fr-FR": {
            notificationsSubtext: "Notifications",
            groupSubtext: "Groupe",
            translateSubtext: "Traduire",
            sharedSubtext: "Écran partagé",
            addSubtext: "",
            accountSubtext: "Compte",
            settingsSubtext: "Paramètres"
        },
        "de-DE": {
            notificationsSubtext: "Benachrichtigungen",
            groupSubtext: "Gruppe",
            translateSubtext: "Übersetzen",
            sharedSubtext: "Gemeinsamer Bildschirm",
            addSubtext: "",
            accountSubtext: "Konto",
            settingsSubtext: "Einstellungen"
        },
        "it-IT": {
            notificationsSubtext: "Notifiche",
            groupSubtext: "Gruppo",
            translateSubtext: "Traduci",
            sharedSubtext: "Schermo condiviso",
            addSubtext: "",
            accountSubtext: "Conto",
            settingsSubtext: "Impostazioni"
        },
        "hi-IN": {
            notificationsSubtext: "सूचनाएँ",
            groupSubtext: "समूह",
            translateSubtext: "अनुवाद करें",
            sharedSubtext: "साझा स्क्रीन",
            addSubtext: "",
            accountSubtext: "खाता",
            settingsSubtext: "सेटिंग्स"
        }

    },
    chatScreenContent: {
        "en-US": {
            conversationModalHeader: "Group Conversations",
            translateModalHeader: "Personal Translations",
            sharedModalHeader: "Shared Screen"
        },
        "en-GB": {
            conversationModalHeader: "Group Conversations",
            translateModalHeader: "Personal Translations",
            sharedModalHeader: "Shared Screen"
        },
        "es-ES": {
            conversationModalHeader: "Conversaciones Grupales",
            translateModalHeader: "Traducciones Personales",
            sharedModalHeader: "Pantalla Compartida"
        },
        "es-MX": {
            conversationModalHeader: "Conversaciones Grupales",
            translateModalHeader: "Traducciones Personales",
            sharedModalHeader: "Pantalla Compartida"
        },
        "fr-FR": {
            conversationModalHeader: "Conversations de Groupe",
            translateModalHeader: "Traductions Personnelles",
            sharedModalHeader: "Affichage Partagé"
        },
        "de-DE": {
            conversationModalHeader: "Gemeinsamer Bildschirm",
            translateModalHeader: "Persönliche Übersetzungen",
            sharedModalHeader: "Gemeinsamer Bildschirm"
        },
        "it-IT": {
            conversationModalHeader: "Conversazioni di Gruppo",
            translateModalHeader: "Traduzioni Personali",
            sharedModalHeader: "Schermo Condiviso"
        },
        "hi-IN": {
            conversationModalHeader: "समूह वार्तालाप",
            translateModalHeader: "व्यक्तिगत अनुवाद",
            sharedModalHeader: "साझा स्क्रीन"
        },
    },
    accountPanelContent: {
        "en-US": {title: "My Account"},
        "en-GB": {title: "My Account"},
        "es-ES": {title: "Mi Cuenta"},
        "es-MX": {title: "Mi Cuenta"},
        "fr-FR": {title: "Mon compte"},
        "de-DE": {title: "Mein Konto"},
        "it-IT": {title: "Il mio Account"},
        "hi-IN": {title: "मेरा खाता"}
    },
    settingsPanelContent: {
        "en-US": {title: "Settings"},
        "en-GB": {title: "Settings"},
        "es-ES": {title: "Configuración"},
        "es-MX": {title: "Configuración"},
        "fr-FR": {title: "Paramètres"},
        "de-DE": {title: "Einstellungen"},
        "it-IT": {title: "Impostazioni"},
        "hi-IN": {title: "सेटिंग्स"}
    },
    sessionsTableContent: {
        "en-US": {
            memberHeader: "Members",
            languageHeader: "Languages",
        },
        "en-GB": {
            memberHeader: "Members",
            languageHeader: "Languages",
        },
        "es-ES": {
            memberHeader: "Miembros",
            languageHeader: "Idiomas",
        },
        "es-MX": {
            memberHeader: "Miembros",
            languageHeader: "Idiomas",
        },
        "fr-FR": {
            memberHeader: "Membres",
            languageHeader: "Langues",
        },
        "de-DE": {
            memberHeader: "Mitglieder",
            languageHeader: "Sprachen",
        },
        "it-IT": {
            memberHeader: "Membri",
            languageHeader: "Lingue",
        },
        "hi-IN": {
            memberHeader: "सदस्य",
            languageHeader: "भाषाएँ",
        },
    }

};
