import React, {Component} from "react";
import {Button, IconButton, ListItemIcon, MenuItem} from "@mui/material";
import {clearLogout} from "../../helpers/settings";
import {clearChatSession, clearChatSessionUser} from "../../helpers/session";
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from "@mui/icons-material/Settings";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonIcon from '@mui/icons-material/Person';
import {Link} from "react-router-dom";
import Menu from '@mui/material/Menu';
import LanguageSelector from "../embeddedSelectors/languageSelector";

export default class UserWidgetsTopRight extends Component {

    state = {
        open: false,
        anchorEl: null,
        redirect: false,
        notificationsOpen: false,
        accounts: [],
        notifications: []
    };

    logOut() {
        clearLogout();
        clearChatSession();
        clearChatSessionUser();
        window.location = '/';
    }

    handleMenuClick = (event) => {
        event.preventDefault();
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => { this.setState({ anchorEl: null }); };

    setLanguage = (lang) => {

    }

    render() {
        return (
            <div style={{display: "flex", marginRight: "10px"}}>
                <LanguageSelector context={"siteLanguage"} setLanguage={this.setLanguage} size={"compact"} />
                {(!localStorage.getItem("rtoken") || localStorage.getItem("rtoken") === "undefined") ?
                    null :

                    <div style={{display: "flex", marginLeft: "10px"}}>

                        <div style={{}}><IconButton
                            aria-controls={this.state.open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={Boolean(this.state.anchorEl) ? 'true' : undefined}
                            onClick={this.handleMenuClick}
                        ><PersonIcon style={{fontSize: "20px"}}/></IconButton></div>


                        <Menu
                            anchorEl={this.state.anchorEl}
                            id="account-menu"
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleMenuClose}
                            onClick={this.handleMenuClose}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{horizontal: 'right', vertical: 'top'}}
                            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                        >
                            <MenuItem component={Link} style={{display: "none"}}
                                      to='/settings/account'><ListItemIcon><ManageAccountsIcon/></ListItemIcon>My
                                account</MenuItem>
                            <MenuItem onClick={this.logOut}><ListItemIcon><LogoutIcon/></ListItemIcon>Logout</MenuItem>
                        </Menu>
                    </div>
                }
            </div>

        )
    }
}
