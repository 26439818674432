import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ForumIcon from '@mui/icons-material/Forum';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {req_join_session, req_reset_password} from "../../helpers/requests";
import {createChatSession, createChatSessionUser, createSelectedAccount} from "../../helpers/session";
import LanguageSelector from "../embeddedSelectors/languageSelector";


const theme = createTheme();

export default function ChatInvitation() {

    const [loading, setLoading] = React.useState("");
    const [language, setLanguage] = React.useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log(data.get('inviteCode'))

        if (data.get('inviteCode')){

        }
        setLoading(true);
        req_join_session(data.get('inviteCode'), language).then((response) => {
            console.log("req_join_session response", response);
            if (response.status === 200) {
                setLanguage(response.data.language)
                createChatSession(data.get('inviteCode'));
                createChatSessionUser(response.data.userId);
                createSelectedAccount(response.data.sessionAccount)
                updateSession();
                setLoading(false);
                window.location = '/';

            } else {
                alert("Invalid session code", data.get('inviteCode'));
                setLoading(false);
            }
        })
    };

    const updateSession = () => {
        if (localStorage.getItem("cSessionId") && localStorage.getItem("cSessionId") !== "undefined") {
        //    this.setState({chatSessionId: localStorage.getItem("cSessionId"), open: false});
        //    this.props.updateSession(localStorage.getItem("cSessionId"), this.state.language);
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'main' }}>
                        <ForumIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Join the Conversation!
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="inviteCode"
                            label="Chat Invite Code"
                            name="inviteCode"
                            autoFocus
                        />
                        <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                            <LanguageSelector language={language} setLanguage={setLanguage}
                                              label={"My Language"} mutliSelect={false}/>
                        </div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, backgroundColor: "#424242" }}
                        >
                            Join
                        </Button>

                    </Box>
                </Box>

            </Container>
        </ThemeProvider>
    );
}
