import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useSearchParams } from "react-router-dom";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {req_login_refresh} from "../../helpers/requests";
import {auth} from "../../helpers/firebase";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import {
    FacebookLoginButton,
    GoogleLoginButton
} from 'react-social-login-buttons';
import {content} from "../../globals/content";
const theme = createTheme();



export default function LogIn(props) {

    const [loading, setLoading] = React.useState(false);
    const [showError, setShowError] = React.useState(false);
    const [showSocialError, setShowSocialError] = React.useState(false);
    const [searchParams] = useSearchParams();
    const provider = new GoogleAuthProvider();
    console.log("provider", provider)

    console.log(searchParams.get('redirect'))

    const handleGoogleLogin = () => {
        //console.log("handleGoogleLogin")

        signInWithPopup(auth, provider).then((userCredential) => {

            //console.log("t1")

            const token = userCredential.user.stsTokenManager.refreshToken;
            console.log("userCredential", userCredential.user);
            let body = {
                email: userCredential.user.email,
                emailVerified: userCredential.user.emailVerified,
                isSocial: true,
                socialType: "google",
            }
            //console.log("t2")
            setLoading(true);
            req_login_refresh(token, body).then(function (result) {
                //console.log("t3")

                if (result.status === 200) {
                    setLocalStorageVals(result);
                    if (searchParams.get('redirect')) {
                        window.location = searchParams.get('redirect');
                    } else {
                        window.location = '/';
                    }


                    setLoading(false)
                } else {
                    setLoading(false);
                    setShowSocialError(true);
                    setTimeout(() => setShowSocialError(false), 5000);
                }

            })

        }).catch((error) => {
            console.log("in catch block", error)
            const errorCode = error.code;
            const errorMessage = error.message;
            const email = error.customData.email;
            const credential = GoogleAuthProvider.credentialFromError(error);

        });
    }

    const handleFacebookLogin = () => {
        console.log("handleFacebookLogin")
    }

    const toggleRegister = () => {
        props.toggleRegister()
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setLoading(true)
        auth.signInWithEmailAndPassword(data.get('email'), data.get('password'))
            .then((userCredential) => {
                const token = userCredential.user.multiFactor.user.stsTokenManager.refreshToken;
                let body = {email: data.get('email'), isSocial: false}
                req_login_refresh(token, body).then(function (result) {

                    if (result.status === 200) {
                        setLocalStorageVals(result);
                        window.location = '/';
                        setLoading(false)
                    } else {
                        setLoading(false);
                        setShowError(true);
                        setTimeout(() => setShowError(false), 5000);
                    }
                })
            }).catch((error) => {

            setLoading(false);
            setShowError(true);
            setTimeout(() => setShowError(false), 5000);

        });
    };

    const setLocalStorageVals = (result) => {
        localStorage.setItem("rtoken", result.data.refreshToken);
        localStorage.setItem("uid", result.data.id);
        localStorage.setItem("settings", JSON.stringify(result.data.settings));
        localStorage.setItem("accounts", JSON.stringify(result.data.accounts));
        localStorage.setItem("ownedAccount", result.data.ownedAccount);
        localStorage.setItem("selectedAccount", result.data.ownedAccount);
        localStorage.setItem("plan", result.data.plan ? result.data.plan : "");
        localStorage.setItem("ownerEmail", result.data.email ? result.data.email : "");
        localStorage.setItem("displayName", result.data.displayName ? result.data.displayName : "");

        if (result.data.settings.account
            && result.data.settings.account[result.data.ownedAccount]
            && result.data.settings.account[result.data.ownedAccount]["tabs"]) {
            localStorage.setItem("portalTabs", JSON.stringify(result.data.settings.account[result.data.ownedAccount]["tabs"]));
        }
    }

    return (
        <ThemeProvider theme={theme}>

            <Container component="main" maxWidth="xs">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>

                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {(showError) ?
                    <Alert severity="error" style={{"width":"100%", "marginBottom": "10px"}}>Login failed. Email or password are incorrect.</Alert> : null
                    }

                    {(showSocialError) ?
                        <Alert severity="error" style={{"width":"100%", "marginBottom": "10px"}}>
                            This Google account has not been registered. Register <Link onClick={toggleRegister} variant="body2">here</Link>.
                        </Alert> : null
                    }

                    <Avatar sx={{ m: 1, bgcolor: 'main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {content.loginScreenContent[localStorage.getItem("siteLanguage") || "en-US"].title}
                    </Typography>




                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>

                        <div >
                            <GoogleLoginButton onClick={handleGoogleLogin} style={{marginBottom: "10px"}}/>
                            <FacebookLoginButton onClick={handleFacebookLogin} style={{marginBottom: "10px", display: "none"}}/>
                        </div>
                        <Divider>Or</Divider>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label={content.loginScreenContent[localStorage.getItem("siteLanguage") || "en-US"].email}
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={content.loginScreenContent[localStorage.getItem("siteLanguage") || "en-US"].password}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 1, mb: 1, backgroundColor: "#424242", textTransform: 'none' }}
                        >
                            {content.loginScreenContent[localStorage.getItem("siteLanguage") || "en-US"].loginButton}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                 <Link href={"/resetpass"} variant="body2">
                                     {content.loginScreenContent[localStorage.getItem("siteLanguage") || "en-US"].passwordResetLinkText}
                                </Link>
                            </Grid>

                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}
