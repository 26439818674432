import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {req_get_handle_lookup, req_login_refresh, req_user_register} from "../../helpers/requests";
import {useState} from "react";
import {setLocalStorageVals} from "../../helpers/settings";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Alert from "@mui/material/Alert";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Link from "@mui/material/Link";
import Dialog from "@mui/material/Dialog";
import PrivacyPolicy from "../content/privacyPolicy";
import TermsOfService from "../content/termsOfService";
import {FacebookLoginButton, GoogleLoginButton} from "react-social-login-buttons";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
//import ReactGA from "react-ga4";
import {Tooltip} from "@mui/material";
import DialogAppBar from "../dialogs/dialogAppBar";
import Divider from "@mui/material/Divider";
import {auth} from "../../helpers/firebase";
import {content} from "../../globals/content";
//ReactGA.initialize(process.env.REACT_APP_GA_ID1,[]);


const theme = createTheme();

export default function Register() {

    const [handleValue, setHandleValue] = React.useState("");
    const [handleError, setHandleError] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [showError, setShowError] = React.useState(false);
    const [showSocialError, setShowSocialError] = React.useState(false);
    const [privacyPolicyOpen, setPrivacyPolicyOpen] = React.useState(false);
    const [termsOfServiceOpen, setTermsOfServiceOpen] = React.useState(false);
    const [registrationComplete, setRegistrationComplete] = React.useState(false);
    const [socialDocsChecked, setSocialDocsChecked] = React.useState(false);
    const provider = new GoogleAuthProvider();
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (!handleError) {
            setShowError(false);
            setLoading(true);
            req_user_register(data.get('email'), data.get('password'), data.get('displayName'), data.get('handle'), true)
                .then(function (result) {
                    if (result.status === 200) {
                        /*
                        ReactGA.event({
                            category: "login events",
                            action: "registration_success",
                            label: "in app"
                        });

                        */
                        setRegistrationComplete(true);
                        setLoading(false)
                    } else {
                        setShowError(true);
                        setLoading(false)
                    }
                })
        } else {
            setShowError(true);
            setLoading(false)
        }
    };

    const handlePPClose = () => { setPrivacyPolicyOpen(false) }

    const handleTOSClose = () => { setTermsOfServiceOpen(false) }

    const handleFacebookRegister = () => {

    }

    const handleGoogleRegister = () => {

        if (socialDocsChecked){
            setShowSocialError(false);
            signInWithPopup(auth, provider).then((userCredential) => {
                setLoading(true);
                const token = userCredential.user.stsTokenManager.refreshToken;

                console.log("userCredential", userCredential)

                let body = {
                    email: userCredential.user.email,
                    emailVerified: userCredential.user.emailVerified,
                    isSocial: true,
                    socialType: "google",
                    docsChecked: true,
                    isRegistration: true
                }

                req_login_refresh(token, body).then(function (result) {

                    if (result.status === 200) {
                        setLocalStorageVals(result);
                        window.location = '/';
                        setLoading(false)
                    } else {
                        setLoading(false);
                        setShowError(true);
                        setTimeout(() => setShowError(false), 5000);
                    }

                })


            }).catch((error) => {
                console.log("in catch block", error)
                const errorCode = error.code;
                const errorMessage = error.message;
                const email = error.customData.email;
                const credential = GoogleAuthProvider.credentialFromError(error);

            });
        } else {
            setShowSocialError(true);
        }
    }




    const confirmAvailability = (handle) => {
        req_get_handle_lookup(handle).then(function (result) {
            if (result.status === 200) {
                if (result.data.inUse > 0){
                    setHandleError(true);
                } else {
                    setHandleError(false);
                }
            }
        })
    }


    return (
        <ThemeProvider theme={theme}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {(showError) ?
                <Alert severity="error" style={{"width":"100%", "marginBottom": "10px"}}>Registration failed. Please review for missing or invalid fields.</Alert> : null
            }
            {(showSocialError) ?
                <Alert severity="error" style={{"width":"100%", "marginBottom": "10px"}}>To register, review and acknowledge the Privacy Policy and Terms of Service confirmation.</Alert> : null
            }
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <div style={{width: "100%", display: registrationComplete ? "none" : "block"}}>

                        <Typography component="h1" variant="h5">
                            {content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].title}
                        </Typography>
                        <div >
                            <GoogleLoginButton text={"Register With Google"} onClick={handleGoogleRegister} style={{marginBottom: "10px"}}/>
                            <FacebookLoginButton onClick={handleFacebookRegister} style={{marginBottom: "10px", display: "none"}}/>
                            <Link

                                variant="body2"
                                onClick={() => {
                                    setPrivacyPolicyOpen(true);
                                }}
                            >{content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].privacyPolicyLinkText}</Link> | <Link
                            variant="body2"
                            onClick={() => {
                                setTermsOfServiceOpen(true);

                            }}
                        >{content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].termsOfServiceLinkText}</Link>

                            <FormControlLabel
                                control={<Checkbox id="docsApprovedSocial" required name="docsApprovedSocial"
                                                   defaultChecked={socialDocsChecked}
                                                   checked={socialDocsChecked}
                                                   onChange={(e) => {setSocialDocsChecked(e.target.checked)}}
                                                   color="primary" />}
                                label={<span style={{ fontSize: '.7rem' }}>{content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].agreementStatement}</span>}
                            />
                        </div>
                        <Divider>Or</Divider>
                        <Box component="form" onSubmit={handleSubmit} validate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label={content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].email}
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <Tooltip placement="top" style={{whiteSpace: "pre-line"}}
                                     title={<div style={{ whiteSpace: 'pre-line' }}>This is the default name that will be shared when chatting with others.</div>}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="displayName"
                                label={content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].displayName}
                                id="displayName"
                                autoComplete="off"
                            />
                            </Tooltip>
                            <Tooltip placement="top" style={{whiteSpace: "pre-line", display: "none"}}
                                title={<div style={{ whiteSpace: 'pre-line' }}>This will be the part of your API URLs that distinguishes you from others.<br />
                                    Example: https://appapi.cognimotion.com/simple_api/*yourhandle*/bestapi - Just the part between the stars.</div>}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                value={handleValue || ""}
                                name="handle"
                                label="Handle"
                                id="handle"
                                error={handleError}
                                helperText={(handleError ? " The selected handle is taken." : "")}
                                onChange={(e) => {
                                    let newHandle = e.target.value.toLowerCase()
                                        .replace(/ /g, '-')
                                        .replace(/[^\w-]+/g, '');
                                    setHandleValue(newHandle)
                                    confirmAvailability(newHandle)}
                                }
                                autoComplete="off"
                            />
                            </Tooltip>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label={content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].password}
                                type="password"
                                id="password"
                                autoComplete="off"
                            />

                            <Link

                                variant="body2"
                                onClick={() => {
                                    setPrivacyPolicyOpen(true);
                                }}
                            >{content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].privacyPolicyLinkText}</Link> | <Link
                            variant="body2"
                            onClick={() => {
                                setTermsOfServiceOpen(true);

                            }}
                        >{content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].termsOfServiceLinkText}</Link>
                            <FormControlLabel
                                control={<Checkbox id="docsApproved" required name="docsApproved" color="primary" />}
                                label={<span style={{ fontSize: '.7rem' }}>{content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].agreementStatement}</span>}

                            />

                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 1, mb: 1, backgroundColor: "#424242", textTransform: 'none' }}
                            >
                                {content.registerScreenContent[localStorage.getItem("siteLanguage") || "en-US"].registerButton}
                            </Button>
                            <Grid container>
                            </Grid>
                        </Box>
                    </div>
                    <div style={{width:"100%", height: "160px", display: registrationComplete ? "block" : "none"}} >
                        Excellent! Your registration was successful. You will receive an email to confirm your account creation. Upon confirming the account, you can log in <Link href="/home">here.</Link>

                    </div>

                </Box>
            </Container>

            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={ privacyPolicyOpen }
                onClose={handlePPClose}

            >
                <DialogAppBar title="Privacy Policy" close={handlePPClose} />

                <Box sx={{padding: 2}}>
                    <PrivacyPolicy />
                    <div align={"right"} style={{marginTop: "10px"}}>
                        <Button variant="contained" size="small"  style={{marginLeft: "10px"}} onClick={handlePPClose}>Close</Button>
                    </div>
                </Box>
            </Dialog>


            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={ termsOfServiceOpen }
                onClose={handleTOSClose}

            >
                <DialogAppBar title="Terms of Service" close={handleTOSClose} />
                <Box sx={{padding: 2}}>

                    <TermsOfService />
                    <div align={"right"} style={{marginTop: "10px"}}>
                        <Button variant="contained" size="small"  style={{marginLeft: "10px"}} onClick={handleTOSClose}>Close</Button>
                    </div>
                </Box>
            </Dialog>

        </ThemeProvider>
    );
}
