import { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {BrowserRouter} from "react-router-dom";
import DynamicRoutes from "./routes/dynamicRoutes";
import { ThemeProvider } from '@mui/material/styles';
import { theme } from "./components/theme/theme";
import eventBus from "./helpers/eventBus";
import logo from "./assets/Logo2.png";
import './App.css';
import UserWidgetsTopRight from "./components/menus/userWidgetsTopRight";
import Link from "@mui/material/Link";

function App() {

    const [updater, setUpdater] = useState("true");

    eventBus.on("updateSiteLanguage", (uObj) => {
        setUpdater(!updater);
    });

    return (
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <div className="App" style={{display: "flex", height: "100%", flexDirection: 'column'}}>
            <CssBaseline />
              <div className={"titleHeader"}>
                  <div style={{marginTop: 10, marginLeft: 10}}><Link href="/"><img src={logo} height={24} /></Link></div>
                  <UserWidgetsTopRight />

              </div>
            <div style={{ paddingLeft: "0", height: "100%", border: "0" }}>
              <DynamicRoutes updater={updater} />
            </div>
          </div>
        </ThemeProvider>
      </BrowserRouter>
    );
}

export default App;
