import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import ChatOptionSelector from "../embeddedSelectors/chatOptionSelector";
import LanguageSelector from "../embeddedSelectors/languageSelector";
import {
    req_create_session_id,
    req_join_session,
    req_personal_session_id,
    req_shared_session_id
} from "../../helpers/requests";
import {createChatSession, createChatSessionUser, createSelectedAccount} from "../../helpers/session";

export default class CreateChat extends Component {

    state = {
        open: true,
        conversationType: "",
        sessionCode: "",
        language: "",
        peerLanguage: "",
        loading: false,
        chatSessionId: "",
    };

    handleClose = () => {
        this.setState({open: false});
        this.props.close()
    }

    setConversationInvoke = (ctype) => {
        this.setState({conversationType: ctype});
    }

    setLanguage = (lang) => {
        this.setState({language: lang});
    }
    setPeerLanguage = (lang) => {
        this.setState({peerLanguage: lang});
    }

    updateSession = () => {
        if (localStorage.getItem("cSessionId") && localStorage.getItem("cSessionId") !== "undefined") {
            this.setState({chatSessionId: localStorage.getItem("cSessionId"), open: false});
            this.props.updateSession(localStorage.getItem("cSessionId"), this.state.language);
        } else {
            this.setState({chatSessionId: undefined});
        }
    }

    createSession = (vConversationType, vSessionCode, vLanguage) => {
        console.log("createSession invoked")
        let self = this;
        console.log(this.state.conversationType, this.state.sessionCode, this.state.language);
        if (vConversationType === "join") {
            console.log(vConversationType, vSessionCode, vLanguage);
            if (!vSessionCode){
                alert("Please enter a session code", vSessionCode);
            }
            else if (!vLanguage){
                alert("Please select your language", this.state.language);
            }
            else {
                self.setState({ loading: true});
                req_join_session(vSessionCode, vLanguage).then((response) => {
                    console.log("req_join_session response", response);
                    if (response.status === 200) {


                        self.setState({language: response.data.language})
                        createChatSession(vSessionCode);
                        createChatSessionUser(response.data.userId);
                        createSelectedAccount(response.data.sessionAccount)
                        self.updateSession();
                        self.setState({ loading: false});
                    } else {
                        alert("Invalid session code", vSessionCode);
                        self.setState({ loading: false});
                    }
                })
            }

        } else if (this.state.conversationType === "remote") {
            console.log("Creating remote session", this.state.language);
            self.setState({ loading: true});
            req_create_session_id(this.state.language).then((response) => {
                console.log("req_create_session_id response", response);
                createChatSession(response.data.newSession);
                createChatSessionUser(response.data.userId);
                self.updateSession();
                self.setState({ loading: false});
            })
        } else if (this.state.conversationType === "shared") {
            console.log("Creating shared session", vLanguage);
            self.setState({ loading: true});
            req_shared_session_id(this.state.language, this.state.peerLanguage).then((response) => {
                //console.log("req_create_session_id response", response);
                createChatSession(response.data.newSession);
                createChatSessionUser(response.data.userId);
                self.updateSession();
                self.setState({ loading: false});
            })
        } else if (this.state.conversationType === "personal") {
            console.log("Creating translate session", this.state.language);
            self.setState({ loading: true});
            req_personal_session_id(this.state.language, this.state.peerLanguage).then((response) => {
                console.log("req_personal_session_id response", response);
                createChatSession(response.data.newSession);
                createChatSessionUser(response.data.userId);
                self.updateSession();
                self.setState({ loading: false});
            })
        }
    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries(formData.entries());
                        const email = formJson.email;
                        console.log(email);
                        this.handleClose();
                    },
                }}
            >
                <DialogTitle>Create or Join a Chat</DialogTitle>
                <DialogContent style={{padding: "10px"}}>
                    <DialogContentText>
                        To creat a chat session, select the type and choose your options.
                    </DialogContentText>
                    <ChatOptionSelector setConversationInvoke={this.setConversationInvoke} mutliSelect={false} />

                    {this.state.conversationType === "join" ?
                        <div style={{width: "100%", margin: "10px auto 5px auto"}}>

                            <div style={{textAlign: "center"}}>
                                <TextField value={this.state.sessionCode}
                                           label={"Session Code Here"}
                                           onChange={(e) => {
                                               this.setState({sessionCode: e.target.value})
                                           }} style={{width: "300px"}}
                                >
                                </TextField>
                            </div>
                        </div>
                        : null
                    }

                    {this.state.conversationType ?
                        <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                            <LanguageSelector language={this.state.language} setLanguage={this.setLanguage}
                                              label={"My Language"} mutliSelect={false}/>
                        </div>
                        : null
                    }

                    {this.state.conversationType === "shared" || this.state.conversationType === "personal" ?
                        <div style={{textAlign: "center", margin: "10px auto", width: "300px"}}>
                            <LanguageSelector language={this.state.peerLanguage} setLanguage={this.setPeerLanguage} label={"Translate To Language"} mutliSelect={true} />

                        </div>: null
                    }

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose}>Cancel</Button>
                    <Button onClick={this.createSession}>Let's Go!</Button>
                </DialogActions>
            </Dialog>
        );
    }
}
