import React, { Component, useRef } from 'react';
import {v4 as uuidv4} from "uuid";
import { Button, IconButton } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import NotStartedIcon from '@mui/icons-material/NotStarted';
import StopIcon from '@mui/icons-material/Stop';
import CircularProgress from '@mui/material/CircularProgress';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import axios from "axios";
import Avatar from '@mui/material/Avatar';

export default class MessageHistory extends Component {
    state = {
        audioFiles: {},
        audioPending: [],
        activeAudio: false,
        currentAudioFile: "",
        currentAudioId: ""
    }



    generateAudio = (message, autoplay = false) => {
        let audioFiles = this.state.audioFiles;
        if (!this.state.audioFiles.hasOwnProperty(message.messageId) && !this.state.audioPending.includes(message.messageId)) {
            let audioPending = this.state.audioPending;
            audioPending.push(message.messageId)
            this.setSourceButtons({audioPending: [...audioPending]})

            //console.log("generateAudio", message)

            let self = this;

            if (message.audioUrl !== "") {
                //console.log("has audioUrl")

                audioFiles[message.messageId] = {
                    messageId: message.messageId,
                    fileData: null,
                    text: message.message,
                    url: message.audioUrl
                }
                self.setState({audioFiles: {...audioFiles} })
                if (autoplay) { self.playAudio(message.messageId) }
            } else {
                //console.log("no audioUrl")
            }
        }

    }

    playAudio = (messageId) => {
        console.log("playAudio", messageId)

        try {
            this.setState({
                currentAudioFile: this.state.audioFiles[messageId].url,
                currentAudioId: messageId,
                activeAudio: true
            })
            let audio = document.getElementById("audioPlayer");
            audio.src = this.state.audioFiles[messageId].url;
            //console.log("audio", audio)
            audio.muted = false;
            audio.autoPlay = true;
            audio.play();

            audio.onEnded = () => {
                console.log("audio ended");

            };
        } catch (e) {
            console.log("error", e)
        }
    }

    pauseAudio = (messageId) => {
        console.log("pauseAudio", messageId)
        let audio = document.getElementById("audioPlayer");
        audio.pause();
        this.setState({activeAudio: false})
    }

    resetAudio = () => {
        console.log("resetAudio")
        this.setState({activeAudio: false})
    }

    MessageBlock = (props) => {
        //console.log(props)
        //console.log(props.message)
        if (props.message.sender === "user") {
            return this.promptBubble(props.message)
        } else if (props.message.sender === "userFunc" || props.message.sender === "sessionFunc"){
            return this.userFuncBubble(props.message)
        }
        else if (props.message.sender === "userFunc"){
            return this.userFuncBubble(props.message)
        }
        else
            return this.responseBubble(props.message, props.index, props.length)
    }

    showSources = (message) => {
        //console.log("props", this.props)
        if (this.props.interfaceObject.hasOwnProperty("properties")
            && this.props.interfaceObject.properties.hasOwnProperty("showSources")
            && this.props.interfaceObject.properties["showSources"] === true){
            if (message.payload && Object.keys(message.payload).includes("sourcesx")) {
                //console.log("load", message["payload"])
                //console.log("load", message["payload"]["sourcesx"] )

                if (message["payload"].hasOwnProperty("sourcesx")) {

                    console.log("has sources")
                    if (message.payload.sourcesx.length > 0) {
                        return true;
                    }
                }
            }
        }

        return false;
    }

    setSourceButtons = (props) => {
        //console.log("props:", props.message)
        //return props.message.payload.sourcesx.map((source) => {
        //    return <Button>test {source.doc}</Button>
        //})
        return null
    }

    /*
    isPlaying = (messageId) => {
        console.log("playing", messageId, Object.keys(this.state.audioFiles))

        Object.keys(this.state.audioFiles).forEach((key) => {
            if (this.state.audioFiles[key].messageId !== messageId) {
                let audio = document.getElementById(messageId);
                audio.pause()
            }
        })
        this.setState({activeAudio: true})
    }
    */

    responseBubble = (message, ind, len) => {
        //console.log("response message", message)
        this.generateAudio(message, (ind === (len - 1)))
        if (message.hasOwnProperty("message") && message.message !== "") {
            return (<div key={message.messageId}
                         style={{
                             padding: "5px",
                             borderRadius: "5px",
                             textAlign: "left",
                             display: "flex",
                             flexDirection: "row",
                             border: "1px solid #ddd",
                             backgroundColor: "#f7f7f7",

                             marginBottom: "15px",
                             fontSize: ".8rem",
                             marginLeft: "5px",
                             marginRight: "5px",
                             color: "#494949"
                         }}
                         className={"chat chat-ai"}>
                <div style={{width: "50px", fontSize: ".7rem"}}>[{message.translatedTo || message.language}]</div>
                <div key={message.messageId} style={{width: "100%"}}
                     dangerouslySetInnerHTML={{__html: message.message.replaceAll(/(\r\n|\r|\n)/g, '<br />').replaceAll("\n", '<br />')}}/>

                <div style={{borderRadius: "20px", width: "40px", textAlign: "right"}}>
                    {this.state.audioFiles.hasOwnProperty(message.messageId) ?
                        <div style={{textAlign: "right"}}>
                            {this.state.currentAudioId === message.messageId && !this.state.activeAudio ?
                                <IconButton onClick={(e) => {
                                    this.playAudio(message.messageId);
                                }}>
                                    <NotStartedIcon size={20}/>
                                </IconButton> :
                                null
                            }
                            {this.state.currentAudioId === message.messageId && this.state.activeAudio ?
                                <IconButton onClick={(e) => {
                                    this.pauseAudio(message.messageId);
                                }}>
                                    <StopIcon size={20}
                                              style={{fill: this.state.currentAudioId === message.messageId && this.state.activeAudio ? "green" : "inherit"}}/>
                                </IconButton> :
                                null
                            }
                            {this.state.currentAudioId !== message.messageId ?
                                <IconButton onClick={(e) => {
                                    this.playAudio(message.messageId);
                                }}>
                                    <NotStartedIcon size={20}/>
                                </IconButton> : null
                            }
                        </div>
                        : <CircularProgress size={20}/>
                    }
                </div>

                {this.showSources(message) ?
                    <div style={{clear: "both", fontSize: ".7rem"}}>
                        Sources: <this.setSourceButtons message={message}/>
                    </div>
                    : null
                }
            </div>)
        }
    }

    promptBubble = (message) => {
        //console.log("message", message)
        if (localStorage.getItem("cSessionUserId") === message.senderId) {
            return (<div style={{position: "relative", marginTop: "10px", marginLeft: "5px", borderTop: "1px solid #f5f5f5"}}><div key={message.messageId}
                            style={{padding: "20px 5px 15px 5px", backgroundColor: "#fff", textAlign: "left", color: "#444", fontSize: ".8rem", marginTop: "1px solid #ddd", marginLeft: "20px", marginRight: "44px"}}
                         className={"chat chat-human"} dangerouslySetInnerHTML={{__html:message.message.replaceAll(/(\r\n|\r|\n)/g, '<br />')}} />
                    <div style={{position: "absolute", color: "#111", top: "0", padding: "0px 6px 2px 5px", fontSize: ".7rem", marginLeft: "20px"}}>
                        {(localStorage.getItem("displayName") !== "" ? localStorage.getItem("displayName") : "me") + ": " + new Date(message.sent).toLocaleString()}
                    </div>
                <Avatar sx={{ width: 24, height: 24, position: "absolute", left: "-10px", top: "5px", fontSize: ".8rem" }}>{message.senderDisplayName[0]}</Avatar>
            </div>)
        } else {
            //console.log("message", message)
            if (message.hasOwnProperty("message") && message.message !== "" && message.message !== null) {
                return (<div style={{position: "relative", marginTop: "10px", marginLeft: "5px", borderTop: "2px solid #f5f5f5"}}>
                    <div key={message.messageId}
                         style={{
                             padding: "20px 5px 15px 5px",
                             marginLeft: "20px",
                             color: "#444",
                             textAlign: "left",
                             fontSize: ".8rem"
                         }}
                         className={"chat chat-human"}
                         dangerouslySetInnerHTML={{__html: message.message.replaceAll(/(\r\n|\r|\n)/g, '<br />')}}/>
                    <div style={{
                        position: "absolute",
                        color: "#111",
                        top: "0",
                        padding: "0px 6px 2px 5px",
                        fontSize: ".7rem",
                        marginLeft: "20px"
                    }}>{message.senderDisplayName + ": " + new Date(message.sent).toLocaleString()}</div>
                    <Avatar sx={{  width: 24, height: 24, position: "absolute", left: "-10px", top: "5px", fontSize: ".8rem" }}>{message.senderDisplayName[0]}</Avatar>
                </div>)
            }
        }
    }
    userFuncBubble = (message) => {

        return (<div className={"chat chat-human"} style={{padding: "3px", borderRadius: "10px", border: "1px dashed #aaa", color: "#555", marginLeft: "60px", marginRight: "60px"}}>
            <ChangeCircleIcon style={{fontSize: "20px", marginRight: "10px", marginTop: "5px"}} /><div style={{display: "inline-block", paddingTop: 0, marginBottom: "5px"}} key={message.messageId} dangerouslySetInnerHTML={{__html:message.message.replaceAll(/(\r\n|\r|\n)/g, '<br />')}} /></div>)

    }

    render() {
        //console.log("messages", this.props.messages)
        return (<>

            <audio id="audioPlayer" controls onEnded={this.resetAudio} style={{width: 150, height: 25, display: "none"}}>
                {this.state.currentAudioFile && this.state.currentAudioFile !== "" ?
                  <source
                    src={this.state.currentAudioFile}
                    type="audio/mp3" />
                  : null
                }
            </audio>
            {this.props.messages.map((message, index) => {
                return <this.MessageBlock key={uuidv4()} message={message} index={index} length={this.props.messages.length} />
            })}

        </>)
    }
}
